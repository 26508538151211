legend {
    font-weight: $font-weight-bold;
}

/* replace "Browse" with font awesome upload icon */
.custom-file-label::after {
    content: "\f093";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

/* remove negative margin-right */
.card-header > .card-tools {
    margin-right: 0;
}

.fas, .far, .fab {
    vertical-align: middle;
}

.pagination-sm .page-dots {
    font-size: .875rem;
    line-height: 1.5;
    padding: .25rem .5rem;
}

.page-dots {
    background-color: #fff;
    border: 1px solid #dee2e6;
    display: block;
    line-height: 1.25;
    margin-left: -1px;
    padding: .5rem .75rem;
    position: relative;
}
