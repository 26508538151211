.user-image-placeholder {
  width: 2rem;
  height: 2rem;
  background: hsla(0, 0%, 100%, .2);
  text-align: center;
  margin: auto;
  color: #fff;
  font-weight: 600;
  font-size: 1em;
  line-height: 2.1rem;
}

div.table > div.table-row[data-toggle="modal"] {
  color: inherit;
  cursor: pointer;
}


.table_filter {
  > .row {
    > div.col-auto:not(:last-of-type) {
      border-right: 1px solid #dddddd;
    }
  }

  .double-range input {
    width: 70px;
  }
}

.card.epitable.sticky-header .card-header {
  background: #f8f9fa;
}
