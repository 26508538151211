div.table {
    display: table;

    &.table-hover {
        .table-row:hover {
            color: #212529;
            background-color: rgba(#000000, .075);
        }
    }

    &.animated {
        .table-row {
            transition: background-color 100ms linear;
        }
    }

    .table-row {
        display: table-row;

        a {
            color: inherit;
        }

        .table-col {
            display: table-cell;
            padding: 0.75rem;
            vertical-align: middle;
            white-space: nowrap;
            border-top: 1px solid #dee2e6;

            &:first-child {
                padding-left: 1.25rem;
            }

            &:last-child {
                padding-right: 1.25rem;
            }

            a:hover {
                color: #007bff;
            }
        }

        &:first-child {
            .table-col {
                border-top: none;
            }
        }
    }
}

form.table_filter {
    div.row{
        row-gap: 15px;
    }
    label {
        font-size: .875rem;
        color: #6c757d;
        margin-bottom: 0;
        margin-right: .5rem;

        &:not(.form-check-label):not(.custom-file-label) {
            font-weight: 400;
        }
    }
}

.card.epitable.sticky-header .card-header {
    position: sticky;
    top: 57px;
    background: #ffffff;
}
